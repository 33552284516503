/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'

import {Topbar} from './Topbar'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  const [,i18n] = useTranslation("global");
  let idioma : any = null;
  idioma= sessionStorage.getItem('idioma');
  if(idioma === null){
    idioma='ESPAÑOL';
}


  useEffect(()=>{
    
    if(idioma.localeCompare("INGLÉS") === 0){
      i18n.changeLanguage("en");
  }else if(idioma.localeCompare("FRANCÉS") === 0){
      i18n.changeLanguage("fr");           
  }else if(idioma.localeCompare("ESPAÑOL") === 0){
      i18n.changeLanguage("es");         
  } 
  },[])
  
  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      ></div>
      {/* begin::Aside mobile toggle */}
          {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
            <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between flex-lg-grow-1'
        )} style={{marginLeft: '30%',marginTop: 14}}
      ><h1 style={{
        color: "#525cdf", fontFamily: 'Arial Black', fontWeight: 'bold', textShadow: ' 0px 3px 3px rgba(255,255,255,0.4),0px -1px 1px rgba(0,0,0,0.3)'
        
          }}>Panel de Administración</h1></div> 
      




       {/* begin::Wrapper */}
       <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
             
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              
            </div>
          )}

          <div style={{alignContent:'right'}} className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    
  )
}
