import React, { useEffect, useState} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
import { MasterLayout } from '../_metronic/layout/MasterLayoutPC';
import Cliente from './models/Cliente';
import { getClientByID, getPasarelaOptions, getPasarelas, getUsers, updateClient } from './RequestMaker';
import { FormItem } from './Widgets/FormItem';

interface UpdateClientParams {
    id: string;
}

type UpdateClientProps = RouteComponentProps<UpdateClientParams>;

export const UpdateClient: React.FC<UpdateClientProps> = (props: UpdateClientProps) => {
    const id = +props.match.params.id;
    const history = useHistory();
    const items = [
        {name: 'idTicando', required: true, placeholder: 'ID Ticando', type: 'text', readOnly: false},
        {name: 'Cliente', required: true, placeholder: 'Nombre de empresa', type: 'text', readOnly: false},   
        {name: 'PUBLICAPIKEY', required: true, placeholder: 'PUBLIC APIKEY', type: 'text', readOnly: false},   
        {name: 'PRIVATEAPIKEY', required: true, placeholder: 'PRIVATE APIKEY', type: 'text', readOnly: false},                   
    ]

    const items2 = [
        {name: 'email', required: true, placeholder:'Email', type: 'text', readOnly: false},   
        {name: 'Dominio', required: true, placeholder:'Dominio', type: 'text', readOnly: false}
    ]

    const [cliente,setCliente] = useState<Cliente>(undefined);
    const [email, setEmail] = useState<string>("")
    const [pasarela_options, setPasarela_options] = useState<any>()
    const [pasarelas, setPasarelas] = useState<any>()
   // const [disabledActualizar, setDisabledActualizar ] = useState<boolean>(false)

    let token = sessionStorage.getItem('token');
    useEffect(() => {
        if (!pasarelas) getPasarelas().then(res => setPasarelas(res));    
    },[])

    useEffect(() => {
        const storage = sessionStorage.getItem('sessionLogin');
        if (!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {
                getClientByID(id,token).then((res) => {
                    setCliente(res);
                    // Filtrar los campos de la pasarela de pago activa
                    let aux = [];
                    res.pasarela_options.forEach(op => {
                        if (op.pasarela_id === res.pasarela_id) {
                            aux.push(op)
                        }
                    });
                    if(aux.length !== 0) setPasarela_options(aux) // En la tabla pasarelaOptions hay definidas filas para la pasarela activa
      
                    getUsers(res.token, res.id).then((res) => {
                        //Filtrado por username admin (Solo hay uno)
                        let admin = res.filter((e) => e.username === 'admin')
                        setEmail(admin[0].email)
                    });
                });          
            }
        }
    }, [id, history]);

    const changeOptionsHandler = (keyPasarela,v) => {
        const newOptions = pasarela_options.map(op => {
            if(op.keyPasarela === keyPasarela)  return { ...op, value: v}
            return op;
        })
        setPasarela_options(newOptions)
    }

    const changeHandler = (name,value) => { 
        if(name === 'pasarelaPago') {
            // Hay que hacer una peticion para ver si la nueva pasarela seleccionada tiene ya filas en la tabla pasarelasOptions
            getPasarelaOptions(value, id, token).then(res => {
                
                if(res.length === 0) { // No existen esos campos en la tabla. Obtenerlos por tabla o codigo duro
                    let pasarela = pasarelas.filter(p => p.name === value)
                    pasarela = pasarela[0]
                    let aux = [];
                    if(pasarela) {
                        pasarela.fields.forEach(field => {
                            let fila = {
                                client_id: id,
                                pasarela_id: pasarela.id,
                                keyPasarela: field.nameField,
                                value: ''
                            }
                            aux.push(fila)
                        })
                        setPasarela_options(aux)
                    }
                } else {
                    setPasarela_options(res)
                }
            })
        }
        setCliente({...cliente, [name]: value})
    }
   
    const updateClientHandler = () => {
        updateClient(token,id,
            cliente.idTicando,
            cliente.Cliente,
            cliente.PUBLICAPIKEY,
            cliente.PRIVATEAPIKEY,
            email,
            cliente.Dominio,
            pasarela_options
        ).then((res) => {
            if(res.statusCode && res.statusCode > 400) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `${res.message}`,
                    footer: ''
                })
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: "Cliente actualizado correctamente",
                    footer: ''
                })
                setTimeout(()=>history.push('/clientes'), 2000);
            }
        }); 
    }

    let disabledActualizar = false;
    if(pasarela_options) {
        pasarela_options.forEach(op => {
            if(op.value === '') disabledActualizar = true;
        })
    }
console.log('pasarela_options ', pasarela_options)
    return(
        <MasterLayout>
            <div className='content'>
            {
                cliente && 
                <div className='col-xl-12'>
                    <div className='card mb-2 mb-xl-5'>
                        <div
                            className='card-header border-0 cursor-pointer'
                            role='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_account_profile_details'
                            aria-expanded='true'
                            aria-controls='kt_account_profile_details'
                        >
                            <div className='card-title m-0' style={{alignContent:'center'}}>
                                <h3 className='fw-bolder m-0'>Cliente ID Ticando {cliente.idTicando}</h3>
                            </div>
                        </div>

                        <div id='kt_account_profile_details' className='collapse show'>                       
                            <div className='card-body border-top p-9'>
                                {
                                    items && items.map(item => {                           
                                        return(
                                            <FormItem  
                                              key={item.name}
                                              name={item.name}
                                              value={cliente[item.name]}
                                              placeholder={item.placeholder}
                                              required={item.required}
                                              onChange={(e)=>changeHandler(e.target.name,e.target.value)} type={item.type} readOnly={item.readOnly}
                                            />
                                        )    
                                    })
                                }
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Pasarela de pago</label>
                                    <div className='col-lg-8 fv-row'>
                                        <select className='form-select form-select-solid form-select-lg' name="pasarelaPago" onChange={(e)=>changeHandler(e.target.name,e.target.value)} >
                                        { !pasarela_options &&
                                            <option value={'select'} selected={true} key={0}>Seleccione una pasarela de pago...</option>
                                        }
                                        {
                                            pasarelas && pasarelas.map(pasarela => {
                                                return <option value={pasarela.name} key={pasarela.id} selected={cliente.pasarela_pago && cliente.pasarela_pago.name === pasarela.name}>{pasarela.name}</option>           
                                            })
                                        }
                                        </select>           
                                    </div>
                                </div>
                                {
                                    pasarela_options && pasarela_options.map((option,index) => {
                                        return(
                                            <FormItem 
                                              key={option.keyPasarela} 
                                              name={option.keyPasarela}
                                              value={option.value}
                                              placeholder={option.keyPasarela}
                                              required={true}
                                              onChange={(e)=>changeOptionsHandler(option.keyPasarela, e.target.value)}
                                              type={option.keyPasarela === 'CHANNEL_ID_TMT' ? 'number' : 'text'} 
                                              readOnly={false}
                                            />
                                        )       
                                    })
                                }
                                {
                                    items2 && items2.map(item => {
                                        if (item.name === 'email') {
                                            return(
                                                <FormItem  
                                                  key={item.name}
                                                  name={item.name}
                                                  value={email}
                                                  placeholder={item.placeholder}
                                                  required={item.required}
                                                  onChange={(e)=>setEmail(e.target.value)} type={item.type} readOnly={item.readOnly}
                                                />
                                            )
                                        } else {
                                            return(
                                                <FormItem 
                                                  key={item.name} 
                                                  name={item.name}
                                                  value={cliente[item.name]}
                                                  placeholder={item.placeholder}
                                                  required={item.required}
                                                  onChange={(e)=>changeHandler(e.target.name,e.target.value)} type={item.type} readOnly={item.readOnly}
                                                />
                                            )
                                        }
                                    })
                                }
                            </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button className="btn btn-secondary" onClick={()=>history.push('/clientes')} >Atrás</button>
                                <button className="btn btn-primary" disabled={disabledActualizar} onClick={updateClientHandler} >Actualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                !cliente &&
                <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                    <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                </div>   
            }  

            </div>
        </MasterLayout>
    );
}