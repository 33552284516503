/* eslint-disable jsx-a11y/anchor-is-valid */
import  { useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import { getLoginPC } from './RequestMaker'
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import { toAbsoluteUrl } from '_metronic/helpers'




const loginSchema = Yup.object().shape({
  user: Yup.string()  
    .required('El usuario es obligatorio.'),
  password: Yup.string()
    .required('La contraseña es obligatoria.'),
})
 
const initialValues = {
  usuario: '',
  password: '',
}


export function Login() {
  
  const [loading, ] = useState(false);
  const history = useHistory();
  const [, setLoginIncorrecto] = useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values:any, {setStatus, setSubmitting}) => {
      getLoginPC(values.user, values.password).then((res) => {
        console.log(res);
        if(res.b) {
            sessionStorage.setItem('sessionLogin', "true");
            sessionStorage.setItem('token',res.t);
            history.push('/')
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Datos incorrectos',
                footer: ''
              })
            sessionStorage.setItem('sessionLogin', "false");
            setLoginIncorrecto(true);
            window.location.reload();
        }
        });

    }
})



  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src="https://res.cloudinary.com/marketingpyme/image/upload/w_160,h_45,c_fill/logo_ticando.png" className='h-75px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Acceso Panel de Administración Civitrip</h1>
      </div>
      {/* begin::Heading */}


      {/* begin::Form group */}
   
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Usuario</label>
        <input
          placeholder=''
          {...formik.getFieldProps('user')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.user && formik.errors.user},
            {
              'is-valid': formik.touched.user && !formik.errors.user,
            }
          )}
          type='user'
          name='user'
          autoComplete='off'
        />
        {formik.touched.user && formik.errors.user && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.user}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Contraseña</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Entrar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

    </form>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>

  )
}



