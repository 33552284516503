import React, {useState, useEffect} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getClientByID, getUsers } from './RequestMaker';
import { MasterLayout } from '../_metronic/layout/MasterLayoutPC';
import Cliente from './models/Cliente';
import { FormItem } from './Widgets/FormItem';
import { Spinner } from 'reactstrap';

interface ViewClientParams {
    id: string;
}

type ViewClientProps = RouteComponentProps<ViewClientParams>;

export const ViewClient: React.FC<ViewClientProps> = (props: ViewClientProps) => {

    const items = [
        {name: 'idTicando', required: false, placeholder: 'ID Ticando', type: 'text', readOnly: true},
        {name: 'Cliente', required: false, placeholder: 'Nombre de empresa', type: 'text', readOnly: true},   
        {name: 'PUBLICAPIKEY', required: false, placeholder: 'PUBLIC APIKEY', type: 'text', readOnly: true},   
        {name: 'PRIVATEAPIKEY', required: false, placeholder: 'PRIVATE APIKEY', type: 'text', readOnly: true},
        {name: 'pasarela_pago', required: false, placeholder: 'Pasarela de pago', type: 'text', readOnly: true}                   
    ]

    const items2 = [
        {name: 'email', required: false, placeholder:'Email', type: 'text', readOnly: true},   
        {name: 'Dominio', required: false, placeholder:'Dominio', type: 'text', readOnly: true}
    ]

    const history = useHistory();
    const id = props.match.params.id;
    const [cliente, setCliente] = useState<Cliente>();
    const [email, setEmail] = useState<string>("")
    const [pasarela_options, setPasarela_options] = useState<any>([]);

    let token = sessionStorage.getItem('token');
    useEffect(() => {
        const storage = sessionStorage.getItem('sessionLogin');
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {
                if(!cliente) {
                    getClientByID(+id,token).then((res) => {
                        setCliente(res);

                        // Filtrar los campos de la pasarela de pago activa
                        let aux = [];
                        res.pasarela_options.forEach(op => {
                          if(op.pasarela_id === res.pasarela_id){
                            aux.push(op)
                          }
                        });
                        setPasarela_options(aux)

                        getUsers(res.token, res.id).then((res)=>{
                            //Filtrado por username admin (Solo hay uno)
                            let admin = res.filter((e)=>e.username === 'admin')
                            setEmail(admin[0].email)  
                        });
                    });
                }
            }
        }
    }, [cliente, id, history]);
console.log('cliente ',cliente)
console.log('pasarelaOptions ',pasarela_options)

    return(
        <MasterLayout>
            {
                cliente &&
                <div className='container'>
                 <div className='col-xl-12'>
                    <div className='card mb-2 mb-xl-5'>
                        <div
                            className='card-header border-0 cursor-pointer'
                            role='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_account_profile_details'
                            aria-expanded='true'
                            aria-controls='kt_account_profile_details'
                        >
                            <div className='card-title m-0' style={{alignContent:'center'}}>
                            <h3 className='fw-bolder m-0'>Cliente {cliente.Cliente} con ID Ticando {cliente.idTicando}</h3>
                            </div>
                        </div>

                        <div id='kt_account_profile_details' className='collapse show'>                       
                            <div className='card-body border-top p-9'>
                                {
                                   items && items.map(item =>{
                                       if(item.name === 'pasarela_pago'){
                                           console.log(cliente[item.name]?.name)
                                            return(
                                                <FormItem  
                                                name={item.name}
                                                value={cliente[item.name]?.name}
                                                placeholder={item.placeholder}
                                                required={item.required}
                                                onChange={(e)=>{}} type={item.type} readOnly={item.readOnly}
                                            />
                                            )    
                                       }else{
                                            return(
                                                <FormItem  
                                                name={item.name}
                                                value={cliente[item.name]}
                                                placeholder={item.placeholder}
                                                required={item.required}
                                                onChange={(e)=>{}} type={item.type} readOnly={item.readOnly}
                                            />
                                            )    
                                       }
                                   
                                    })
                                }
                                {
                                    pasarela_options && pasarela_options.map(option =>{
                                        return(
                                            <FormItem  
                                            name={option.keyPasarela}
                                            value={option.value}
                                            placeholder={option.keyPasarela}
                                            required={false}
                                            onChange={(e)=>{}} type={'text'} readOnly={true}
                                        />
                                        )       
                                    })
                                }

                                {
                                    items2 && items2.map(item =>{
                                        if(item.name === 'email'){
                                            return(
                                                <FormItem  
                                                name={item.name}
                                                value={email}
                                                placeholder={item.placeholder}
                                                required={item.required}
                                                onChange={(e)=>{}} type={item.type} readOnly={item.readOnly}
                                            />
                                            )
                                        }else{
                                            return(
                                                <FormItem  
                                                name={item.name}
                                                value={cliente[item.name]}
                                                placeholder={item.placeholder}
                                                required={item.required}
                                                onChange={(e)=>{}} type={item.type} readOnly={item.readOnly}
                                            />
                                            )
                                        }

                                    })
                                }
                            </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button onClick={()=>history.push('/clientes')} className="btn btn-secondary" >Atrás</button>                        </div>
                            </div>
                        </div>                   
                    </div>
                </div>
            }
            {
                !cliente &&
                <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                </div>   
            }                          
        </MasterLayout>
    );
}

