import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { MasterLayout } from '../_metronic/layout/MasterLayoutPC';
import { getReservas } from './RequestMaker';

import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';

import { Icons } from "material-table";

import { FirstPage, LastPage } from "@material-ui/icons";
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import { UserOptions } from 'jspdf-autotable';
import { Spinner } from 'reactstrap';
import ReactMonthPicker from 'react-month-picker'
import "react-month-picker/css/month-picker.css";
import { MonthPickerOwn } from './Widgets/MonthPicker';
import { months } from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { formatoFecha } from 'utils/MyGlobalContext';

export const Reservas: React.FC = () => {

    const history = useHistory();
    const [reservas,setReservas] = useState<any>();
    const [reservas_filtradas,setReservas_filtradas] = useState<any>();
    const [openTickets, setOpenTickets] = useState<boolean>(false);
    const [idReserva, setIdReserva] = useState<number>(0);
    const [,setFechaCom] = useState<string>("");

    //prueba
    const [month, setMonth] = useState({ year: 0, month: 0 })


const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


    const columns = [
        {title: "Cliente", field: "Cliente"},
        {title:"UUID", field: "uuid"},
        {title:"Nombre", field: "Nombre"},
        {title:"Apellidos", field: "Apellidos"},
        {title:"Teléfono", field: "Telefono"},
        {title:"Email", field: "Email"},
        {title:"Total", field: "Total",render:(rowData)=>{
            return(
                <div>
                {rowData.Total}
            </div>
            )

        }},
        {
            title:"Moneda", field: "money"
        },
        {title:"Fecha Compra", field: "paid_at", render:(rowData)=>{
            if(rowData.paid_at !== "0000-00-00 00:00:00"){
                let paid_at = new Date(rowData.paid_at)
                let minutes = paid_at.getMinutes() < 10 ? '0'+paid_at.getMinutes() : paid_at.getMinutes()
                let seconds = paid_at.getSeconds() < 10 ? '0'+paid_at.getSeconds() : paid_at.getSeconds()
                return(
                    <div>
                        {paid_at.getDate()}-{paid_at.getMonth() + 1}-{paid_at.getFullYear()} {paid_at.getHours()}:{minutes}:{seconds}
                    </div>
                )
            } else {
                return(
                    <div>
                        -
                    </div>
                )
            }    
        }},
        //\nNombre    Fecha Compra    Fecha Reservada    Cantidad reservada    Código
       /* {title: "Tickets", field: "items", render: rowData => 
        <button onClick={()=>{setOpenTickets(true);
            setIdReserva(rowData.id)}} className="btn btn-primary" title="Ver" data-toggle="tooltip">
                    Ver tickets
            </button>}*/
    ]

    const headers = [
        {title:"Cliente", field: "Cliente"},
        {title:"UUID", field: "uuid"},
        {title:"Comprador", field: "Comprador"},
        {title:"Total", field: "Total", render:(rowData)=>{
            return(
                <div>
                {rowData.Total} {rowData.money}
            </div>
            )

        }},
        {title:"Fecha Compra", field: "fechaCompra"},
        {title: "Tickets\n\n  Número || Reserva || Fecha reservada", field: "items"}
    ]

    let token = sessionStorage.getItem('token');

    useEffect(() => {
        const storage = sessionStorage.getItem('sessionLogin');
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {
                if(!reservas) {
                    getReservas(token).then((res) => {
                        console.log(res)
                        setReservas(res);
                        setReservas_filtradas(res)
                    });
                }
            }
        }
    }, [history, openTickets]);

    useEffect(() => {
        if(reservas && month.year !== 0){
            console.log('month ',month)
            const mes_filtrado = new Date(month.year,month.month-1)
            const reservas_filtradas = reservas.filter(reserva => {
                let mes;
                if(reserva.paid_at !== "0000-00-00 00:00:00"){
                    mes = new Date(reserva.paid_at).getMonth() + 1
                    console.log(mes === month.month)
                    return mes === month.month
                }

            })
            setReservas_filtradas(reservas_filtradas)
            console.log('mes_filtrado ',mes_filtrado)
            console.log('filtro reservas ', reservas_filtradas)
        }
    },[month])

    const resetMesFiltrado = () => {
        console.log('reset')
        setMonth({ year: 0, month: 0 })
        setReservas_filtradas(reservas)
    }

    const handleClose = () => {
        setOpenTickets(false);
        setIdReserva(null);
    }

    const fechaHandler = useCallback((event) => {
        setFechaCom(event.target.value);
    }, []);

    const downloadExcel = () => {
        let workSheet = XLSX.utils.json_to_sheet([],{header: ["Cliente", "uuid", "Nombre", "Apellidos", "Teléfono", "Email", "Total", "Moneda", "Fecha de compra", "Servicio", "Fecha reservada", "Número"]});
        let fila = 2;
        reservas.map(r=>{
            delete r.id
            delete r.tableData
            delete r.fechaCompra
            console.log('r ',r)
           
            XLSX.utils.sheet_add_json(workSheet,[r], {skipHeader: true, origin: { r: fila, c: 0 }});
            r.items.map(i=>{
                console.log('item ',i) 
                fila = fila + 1;
                delete i.id
                delete i.id_reserva
                delete i.cantidad
                delete i.created_at
                XLSX.utils.sheet_add_json(workSheet, [i], {skipHeader: true, origin: { r: fila, c: 9 }});
            })
            fila = fila + 1;
        })
        const workBook  = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Reservas")
        //Buffer
        let buf = XLSX.write(workBook, {bookType:"xlsx", type:"buffer"})
        //Binary string
        XLSX.write(workBook, {bookType:"xlsx", type:"binary"})
        //Download
       XLSX.writeFile(workBook, "Reservas.xlsx")
    }

    interface jsPDFWithPlugin extends jsPDF {
        autoTable: (options: UserOptions) => jsPDF;
      }
      
    const downloadPdf = () => {

        let reser: any = [];
        reservas_filtradas.map((r)=>{
            let it: string = "";
            
            r.items.map((i)=>{
                let fecha = formatoFecha(i.datebook)
                it = it + i.numero + " || " + i.name + " || " + fecha + "\n";
            })
            let fecha = '-'
            if(r.paid_at !== "0000-00-00 00:00:00") {
                let paid_at = new Date(r.paid_at)
                let minutes = paid_at.getMinutes()
                let mins = minutes.toString()
                if(minutes < 10){
                    mins = '0'+minutes
                }
                let seconds = paid_at.getSeconds()
                let s = seconds.toString()
                if(seconds < 10 ) {
                    s = '0'+seconds
                }
                fecha =  `${paid_at.getDate()}-${paid_at.getMonth() + 1}-${paid_at.getFullYear()} ${paid_at.getHours()}:${mins}:${seconds}`

            }

            reser.push({Cliente: r.Cliente, uuid:r.uuid, Comprador: `${r.Nombre} ${r.Apellidos} \n${r.Email}    ${r.Telefono}`, Total: `${r.Total} ${r.money}`, fechaCompra: fecha, items: it})
        })
        const doc = new jsPDF() as jsPDFWithPlugin;
        doc.autoTable({
            columns:headers.map(col=>({...col,dataKey:col.field})),
            body: reser,
            styles: {
                fontSize: 5
            }
        })
        doc.text("Reservas",20,10);

        doc.save("Reservas.pdf"); 
    }

    return (
        <MasterLayout>
        <div className="container">
            {/*<button className="btn btn-primary" onClick={logOutHandler} >Log Out</button>*/}

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openTickets} >
                <DialogTitle id="customized-dialog-title">RESERVAS</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                    <table className="table">
                                         <thead>
                                             <tr>
                                                 <th scope="col">Nombre</th>
                                                 <th scope="col">Creado</th>
                                                 <th scope="col">Fecha reservada</th>
                                                 <th scope="col">Código</th>
                                             </tr>
                                         </thead>
                                         <tbody>
                        {

                           reservas && reservas.map((r)=>{
                                if(r.id === idReserva){
                                    console.log(r.items);

                                    if(r.items[0]){
                                        return(
                                            r.items.map((i)=>{
                                                return(
                                                <tr>
                                               <td>{i.name}</td> 
                                               <td>{i.created_at}</td> 
                                               <td>{i.datebook}</td> 
                                               <td>{i.numero}</td> 
                                               </tr>
                                                )
                                            })                                         
                                        )
                                    }                        
                                }
                            })
                        }
                        </tbody>
                        </table>
                    </Typography>
                </DialogContent>
            </Dialog>

        </div>
        {
            !reservas &&
            <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
            </div>  
        }{
            reservas && 
            <>

            <div className="container">
                <div className="row justify-content-start">
                    <div className="col-2" style={{width: 130}}><h4>Filtrar por mes: </h4></div>
                    <div className="col-2"><MonthPickerOwn month={month} setMonth={setMonth} resetMesFiltrado={resetMesFiltrado}></MonthPickerOwn></div>
                </div>           
            </div>
            <MaterialTable
            icons={tableIcons}
            title="Reservas"
            columns={columns}  
            data={reservas_filtradas}
            options={{filtering:true, pageSizeOptions:[5,10,25,50,100], paginationType:"stepped",
            rowStyle:(data,index)=>index % 2 === 0 ? {background: "#e8e8e8"}:null}}
            actions={[
                {icon:SaveAlt,
                 tooltip:"Exportar a Excel",
                 onClick:()=>downloadExcel(),
                 isFreeAction:true},
                 {icon:PictureAsPdfOutlinedIcon,
                 tooltip: "Exportar a PDF",
                 onClick:()=>downloadPdf(),
                 isFreeAction:true}
            ]}
            detailPanel={rowData => {
                console.log(rowData)
                
                return (
                    <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align='center'>Número</StyledTableCell>
                        <StyledTableCell align='center'>Servicio</StyledTableCell>
                        <StyledTableCell align='center'>Fecha reservada</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData.items.map((item) => {
                        let fecha = formatoFecha(item.datebook)
                        return(
                            <TableRow key={item.numero}>
                            <TableCell align='center' component="th" scope="row">
                              {item.numero}
                            </TableCell>
                            <TableCell align='center'>{item.name}</TableCell>
                            <TableCell align='center'>{fecha}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                )
              }}
              /*components={{
                FilterRow: (rowProps) => {
                  let { columns, onFilterChanged } = rowProps;
                    columns = [{field:'new'},...columns]
                  return (
                    <>
                      <tr>

                        {columns.map((col) => {
                          if (col.field && col.field !== 'new') {
                            return (
                              <td>
                                <input style={{borderRadius: 5, width: 80}}
                                  placeholder="Filtrar"
                                  id={col.field}
                                  onChange={(e) => {
                                    console.log(e.target.id, e.target.value);
                                    onFilterChanged(col.tableData.id, e.target.value);
                                  }}
                                />
                              </td>
                            );
                          } else {
                            return (
                                <td>

                                </td>
                            )
                          }
                        })}
                      </tr>
                    </>
                  );
                },
              }} */
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            > 
            </MaterialTable> 
            </>
        }

        </MasterLayout>
    );
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));