import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { getClients, getReservas } from './RequestMaker';
import { MasterLayout } from '../_metronic/layout/MasterLayoutPC';
import { StatisticsWidget5 } from '_metronic/partials/widgets/statistics/StatisticsWidget5';
import { Spinner } from 'reactstrap';
import { MonthPickerOwn } from './Widgets/MonthPicker';




export const MainPage: React.FC = () => {
    const history = useHistory();
    const [nclients, setNClients] = useState<number>(0);
    const [nreservas, setNReservas] = useState<number>(undefined);
    const [loading, setLoading] = useState<boolean>(true);



    let token = sessionStorage.getItem('token');
    useEffect(() => {
        
        const storage = sessionStorage.getItem('sessionLogin');
        
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {
               
                    let aux = 0;
                    getClients(token).then((res) => {
                        res.forEach(c => {
                            if(c.disabled_at === null){
                                console.log(c)
                                aux = aux + 1;
                            }
                        });
                        setNClients(aux);
                    });
                
                if(!nreservas){
                    getReservas(token).then((res)=>{
                        setNReservas(res.length)
                        setLoading(false);
                    }) 
                }
                }
            }     
    }, [history, loading]);


    return (
        <MasterLayout>
            {
                loading === false &&
                
                <div className='card mb-2 mb-xl-5'>
                <br/><br/>
               <div className='row g-5 g-xl-8'>
                
                <div className='col-xl-3'>
                </div>
               <div className='col-xl-3'>
                <StatisticsWidget5
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='/media/icons/duotune/communication/com005.svg'
                    color='success'
                    iconColor='white'
                    title={nclients ? nclients.toString() : '0'}
                    description='Clientes activos'
                />
              </div>
              <div className='col-xl-3'>
                    <StatisticsWidget5
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='/media/icons/duotune/ecommerce/ecm001.svg'
                    color='primary'
                    iconColor='white'
                    title={nreservas ? nreservas.toString() : '0'}
                    description='Reservas'
                />
              </div>
              </div>
              <br/><br/>
              </div>
            }
            {
                loading === true &&
                <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                    <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                </div> 
            }

        </MasterLayout> 
    ); 
}
 