import { createContext, useContext } from "react"
export type GlobalContent = {
  copy: string
  setCopy:(c: string) => void
}
export const MyGlobalContext = createContext<GlobalContent>({
copy: '', // set a default value
setCopy: () => {},
})
export const useGlobalContext = () => useContext(MyGlobalContext)

export const formatoFecha = (fecha) => {
  if(fecha !== "0000-00-00 00:00:00"){
    const datebook = new Date(fecha)
    const dia = datebook.getDate()
    const mes = datebook.getMonth()
    const año = datebook.getFullYear()
    const hora = datebook.getUTCHours()
    let minutes = datebook.getUTCMinutes()
    let mins = minutes.toString()
    if(minutes < 10){
      mins = '0'+minutes
    }
    let res;
    if(hora === 0){
        res = `${dia}-${mes + 1}-${año}`
    } else {
        res = `${dia}-${mes + 1}-${año} ${hora}:${mins}h`
    }
    return res
  } else {
    return ""
  }
}