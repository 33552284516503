import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { PanelControl } from '../@types/Data';
import { cambActivacion, getClients } from './RequestMaker';
import { MasterLayout } from '../_metronic/layout/MasterLayoutPC';
import { AddBox, Check, Clear, DeleteOutline, ChevronRight, Edit, SaveAlt, FilterList, FirstPage, LastPage, Search, ChevronLeft, ArrowDownward, Remove, ViewColumn  } from '@material-ui/icons';

import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';


import { Icons } from "material-table";
import { Spinner } from 'reactstrap';

export const Clientes: React.FC = () => {
    const history = useHistory();
    const [clients, setClients] = useState<PanelControl[]>();
    const [deleteClientPopUp, setDeleteClientPopUp] = useState<boolean>(false);
    const [deleteCorrecto, setDeleteCorrecto] = useState<boolean>(false);
    const [deleteIncorrecto, setDeleteIncorrecto] = useState<boolean>(false);
    const [idClienteDelete, setIdClienteDelete] = useState<number>(0);
    const [msg1,setMsg1] = useState<string>("");
    const [msg2,setMsg2] = useState<string>("");
    const [msg3,setMsg3] = useState<string>("");

    const tableIcons: Icons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

    const columns = [
        {title:"Nombre", field: "Cliente",filterPlaceholder:"Filtrar"},
        {title:"Pasarela de Pago", field: "pasarela_pago.name",filterPlaceholder:"Filtrar"},
        {title:"Dominio", field: "Dominio",filterPlaceholder:"Filtrar"}, 
        {title:"Fecha creación", field: "created_at",filterPlaceholder:"Filtrar", render:(rowData)=><div style={{textAlign:'center'}}>{rowData.created_at ? rowData.created_at.substr(0,rowData.created_at.indexOf("T")) : "-" }</div>}, 
        {title:"Fecha modificación", field: "lastmodified_at",filterPlaceholder:"Filtrar",render:(rowData)=><div style={{textAlign:'center'}}>{rowData.lastmodified_at ? rowData.lastmodified_at.substr(0,rowData.lastmodified_at.indexOf("T")) : "-" }</div>},  
        {title: "Desactivado", field: "disabled_at", filterPlaceholder: "Filtrar", render:(rowData)=><div style={{textAlign:'center'}}>{rowData.disabled_at ? rowData.disabled_at.substr(0,rowData.disabled_at.indexOf("T")) : "-" }</div>}
    ]
    let token = sessionStorage.getItem('token');
    useEffect(() => {
        const storage = sessionStorage.getItem('sessionLogin');
        
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {
                if(!clients) {
                    getClients(token).then((res) => {
                        setClients(res);
                    });
                }
            }
        }
    }, [clients, history]);

    const cambiarActivacion = (desactivado,id) => {
        desactivado ? setMsg1("¿Está seguro que desea habilitar de nuevo este cliente?") : setMsg1("¿Está seguro que desea deshabilitar este cliente?");
        desactivado ? setMsg2("Se va a habilitar el cliente, ¿está seguro de ello?") : setMsg2("Se va a deshabilitar el cliente, ¿está seguro de ello?");
        desactivado ? setMsg3("El cliente se ha vuelto a habilitar correctamente.") : setMsg3("El cliente se ha deshabilitado correctamente.");
      
        setDeleteClientPopUp(true);
        setIdClienteDelete(id);
    }

    const deleteClientHandler = () => {
        cambActivacion(token,idClienteDelete).then((res) => {
            if(res === 1) {
                setDeleteCorrecto(true);
            } else {
                setDeleteIncorrecto(true);
            }
        });
    }

    const closeDeletePopUp = () => {
        setDeleteClientPopUp(false);
    }
console.log(clients)
    return (
        <MasterLayout>
        <div className="container">
            <br/><br/>
            <Dialog onClose={closeDeletePopUp} aria-labelledby="customized-dialog-title" open={deleteClientPopUp} >
                <DialogTitle id="customized-dialog-title">{msg1}</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <b>{msg2}</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-primary" onClick={deleteClientHandler} >Sí</button>
                    <button type="button" className="btn btn-primary" onClick={closeDeletePopUp} >No</button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={() => {
                setDeleteCorrecto(false);
                setDeleteClientPopUp(false);
                getClients(token).then((res) => {
                    setClients(res);
                });
            }} aria-labelledby="customized-dialog-title" open={deleteCorrecto} >
                <DialogTitle id="customized-dialog-title">Correcto</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <b>{msg3}</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-primary" onClick={() => {
                            setDeleteCorrecto(false);
                            setDeleteClientPopUp(false);
                            getClients(token).then((res) => {
                                setClients(res);
                            });
                    }} >Cerrar</button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={() => {
                setDeleteIncorrecto(false);
                setDeleteClientPopUp(false);
                getClients(token).then((res) => {
                    setClients(res);
                });
                history.push('/');
            }} aria-labelledby="customized-dialog-title" open={deleteIncorrecto} >
                <DialogTitle id="customized-dialog-title">Incorreto</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <b>Algo ha ocurrido, intentelo de nuevo más tarde.</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-primary" onClick={() => {
                            setDeleteIncorrecto(false);
                            setDeleteClientPopUp(false);
                            getClients(token).then((res) => {
                                setClients(res);
                            });
                        }} >Cerrar</button>
                </DialogActions>
            </Dialog>
        </div>
        {
            clients &&
            <MaterialTable
           icons={tableIcons}
           title="Clientes"
           columns={columns}  
           data={clients}
           actions={[
            {icon:Edit,
            tooltip:"Editar cliente",
            onClick:(e,data:any)=>history.push(`/update/${data.id}`)
            },     
            {icon:AddBox,
            tooltip: "Nuevo cliente", 
            onClick: (e,data:any)=>history.push(`/create`),
            isFreeAction: true
            },
            rowData =>(
                {icon:rowData.disabled_at ? ToggleOffOutlinedIcon : ToggleOnIcon,
                    tooltip: rowData.disabled_at ? "Activar cliente" : "Desactivar cliente",
                    onClick:(e,data:any)=>{cambiarActivacion(data.disabled_at,data.id)}
                }
            ),

            {icon: ChevronRight,
            tooltip: "Ver detalles cliente",
            onClick: (e,data:any)=>history.push(`/view/${data.id}`)},

           ]}
           options={{filtering:true, pageSizeOptions:[5,10,25,50], paginationType:"stepped", actionsColumnIndex: -1,toolbarButtonAlignment:"left",
            rowStyle:(data,index)=>data.disabled_at ? {color: "grey"}:null
            }}> 
           </MaterialTable> 
        }
        {
            !clients && 
                <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                    <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                </div>           
        }
         
        </MasterLayout>
    );
}
