/* eslint-disable react/jsx-no-target-blank */
import  { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { AsideMenuItem } from '_metronic/layout/components/aside/AsideMenuItem';



export function AsideMenuMain(props:any) {
  const [t,i18n] = useTranslation("global");
  let idioma : any = null;
  idioma= sessionStorage.getItem('idioma');
  if(idioma === null){
    idioma='ESPAÑOL';
}
  useEffect(()=>{
    
    if(idioma.localeCompare("INGLÉS") === 0){
      i18n.changeLanguage("en");
  }else if(idioma.localeCompare("FRANCÉS") === 0){
      i18n.changeLanguage("fr");           
  }else if(idioma.localeCompare("ESPAÑOL") === 0){
      i18n.changeLanguage("es");         
  }   

  },[])


    return (
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Dashboard</span>
          </div>
        </div>  
        <AsideMenuItem 
          to={`/`}
          icon='/media/icons/duotune/general/gen001.svg'
          title={t("main.home")} 
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to={`/clientes`}
          title='Clientes'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/communication/com005.svg'
        />    
        <AsideMenuItem
          to={`/reservas`}
          title='Reservas'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/ecommerce/ecm001.svg'
        />        
       {/* <AsideMenuItem
          to={`/cobros`}
          title='Cobros a Clientes'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/finance/fin001.svg'
    />  */}
        <AsideMenuItem
          to='/login'
          title={t("main.logout")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/arrows/arr077.svg'
        />          
      </>
    )
  
  
}
