import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createClient, getPasarelas } from './RequestMaker';
import { MasterLayout } from '../_metronic/layout/MasterLayoutPC';
import Cliente from './models/Cliente';
import { FormItem } from './Widgets/FormItem';
import Swal from 'sweetalert2';

export const CreateClient: React.FC = () => {
    const history = useHistory();

    const items = [
        {name: 'idTicando', required: true, placeholder: 'ID Ticando', type: 'text', readOnly: false},
        {name: 'Cliente', required: true, placeholder: 'Nombre de empresa', type: 'text', readOnly: false},   
        {name: 'PUBLICAPIKEY', required: true, placeholder: 'PUBLIC APIKEY', type: 'text', readOnly: false},   
        {name: 'PRIVATEAPIKEY', required: true, placeholder: 'PRIVATE APIKEY', type: 'text', readOnly: false},                   
    ]

    const items2 = [
        {name: 'email', required: true, placeholder:'Email', type: 'text', readOnly: false},   
        {name: 'Dominio', required: false, placeholder:'Dominio', type: 'text', readOnly: false}
    ]

    const initials = {
        idTicando: "",
        Cliente: "",
        PUBLICAPIKEY : "",
        PRIVATEAPIKEY: "",
        pasarela_pago: "",
        Dominio: "",
        email: "",
    }

    const [cliente, setCliente] = useState<Cliente>(initials);
    const [pasarelas, setPasarelas] = useState<any>()
    const [pasarela_options, setPasarela_options] = useState<any>()

    let token = sessionStorage.getItem('token');

    useEffect(() => {
        if (!pasarelas) getPasarelas().then(res => setPasarelas(res));    
    },[])

    useEffect(() => {
        const storage = sessionStorage.getItem('sessionLogin');
        if (!storage) {
            history.push('/login');
        } else {
            if (!JSON.parse(storage)) history.push('/login');
        }
    }, [history]);
 
    const createClientHandler = () => {
        if (cliente.idTicando && cliente.Cliente) {
            createClient(token,
                cliente.idTicando,
                cliente.Cliente,
                cliente.PUBLICAPIKEY,
                cliente.PRIVATEAPIKEY,
                cliente.email,
                cliente.Dominio,
                pasarela_options
            ).then((res) => {
                if(res.statusCode && res.statusCode > 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text:  `${res.message}`,
                        footer: ''
                    })   
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Éxito',
                        text: "Cliente creado correctamente",
                        footer: ''
                    })
                    setTimeout(()=>history.push('/clientes'), 2000);
                }
            });
        }
    }

    const changeOptionsHandler = (keyPasarela,v) => {
        const newOptions = pasarela_options.map(op => {
            if (op.keyPasarela === keyPasarela) return {...op, value: v}
            return op;
        })
        setPasarela_options(newOptions)
    }

    const changeHandler = (name,value) => {
        if (name === 'pasarelaPago') {
            let pasarela = pasarelas.filter(p => p.name === value)
            pasarela = pasarela[0]
            let aux = [];
            if (pasarela) {
                pasarela.fields.forEach(field => {
                    let fila = {
                        pasarela_id: pasarela.id,
                        keyPasarela: field.nameField,
                        value: ''
        
                    }
                    aux.push(fila)
                })
                setPasarela_options(aux)
            }
        }
        setCliente({...cliente, [name]: value})
    }

    let disabledActualizar = false;
    if (pasarela_options) {
        pasarela_options.forEach(op => {
            if(op.value === ''){
                disabledActualizar = true;
            }
        })
    }

    return (
        <MasterLayout>
            <div className="container">
                <div className='col-xl-12'>
                    <div className='card mb-2 mb-xl-5'>
                        <div
                            className='card-header border-0 cursor-pointer'
                            role='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_account_profile_details'
                            aria-expanded='true'
                            aria-controls='kt_account_profile_details'
                        >
                            <div className='card-title m-0' style={{alignContent:'center'}}>
                                <h3 className='fw-bolder m-0'>CREAR CLIENTE</h3>
                            </div>
                        </div>

                        <div id='kt_account_profile_details' className='collapse show'>    
                            <div className='card-body border-top p-9'>
                                {
                                    items && items.map(item => {
                                        return(
                                            <FormItem 
                                                key={item.name} 
                                                name={item.name}
                                                value={cliente[item.name]}
                                                placeholder={item.placeholder}
                                                required={item.required}
                                                onChange={(e)=>changeHandler(e.target.name,e.target.value)} type={item.type} readOnly={item.readOnly}
                                            />
                                        )
                                    })
                                }

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Pasarela de pago</label>
                                    <div className='col-lg-8 fv-row'>
                                        <select className='form-select form-select-solid form-select-lg' name="pasarelaPago" onChange={(e)=>changeHandler(e.target.name,e.target.value)} >
                                        { !pasarela_options &&
                                            <option value={'select'} key={0}>Seleccione una pasarela de pago...</option>
                                        }
                                        {
                                            pasarelas && pasarelas.map(pasarela =>{
                                                return(
                                                    <option value={pasarela.name} key={pasarela.id} selected={cliente.pasarelaPago === pasarela.name}>{pasarela.name}</option>
                                                )
                                            })
                                        }
                                          </select> 

                                                
                                    </div>
                                </div>
                                {
                                    pasarela_options && pasarela_options.map((option,index) =>{
                                        return(
                                            <FormItem  
                                              key={option.keyPasarela}
                                              name={option.keyPasarela}
                                              value={option.value}
                                              placeholder={option.keyPasarela}
                                              required={true}
                                              onChange={(e) => changeOptionsHandler(option.keyPasarela, e.target.value)} 
                                              type={option.keyPasarela === 'CHANNEL_ID_TMT' ? 'number' : 'text'} 
                                              readOnly={false}
                                            />
                                        )       
                                    })
                                }  
                                {
                                    items2 && items2.map(item => {
                                        return(
                                            <FormItem  
                                              key={item.name}
                                              name={item.name}
                                              value={cliente[item.name]}
                                              placeholder={item.placeholder}
                                              required={item.required}
                                              onChange={(e)=>changeHandler(e.target.name,e.target.value)} type={item.type} readOnly={item.readOnly}
                                        />
                                        )
                                    })
                                }
                            </div>

                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button onClick={()=>history.push('/clientes')} className="btn btn-secondary" >Atrás</button>
                                <button className="btn btn-primary" disabled={disabledActualizar} onClick={createClientHandler}>Crear</button>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}