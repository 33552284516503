import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { MainPage  } from './components/MainPage';
import { CreateClient } from './components/CreateClient';
import { UpdateClient } from './components/UpdateClient';
import { ViewClient } from './components/ViewClient';
import { Login } from './components/Login';
import { Reservas } from 'components/Reservas';
import { Clientes } from 'components/Clientes';
import { MasterInit } from '_metronic/layout/MasterInit';
import { Cobros } from 'components/Cobros';

export const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>

                 {/*Panel Control*/}
                <Route exact path='/login' component={Login}/>
                <Route exact path='/' component={MainPage} />
                <Route exact path='/clientes' component={Clientes}/>
                <Route exact path='/create' component={CreateClient} />
                <Route exact path='/update/:id' component={UpdateClient} />
                <Route exact path='/view/:id' component={ViewClient} />
                <Route exact path='/reservas' component={Reservas} />
               { /*<Route exact path='/cobros' component={Cobros} /> */} 
   
            </Switch>
            <MasterInit />
        </BrowserRouter> 
    ); 
};
