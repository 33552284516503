const baseURL = "https://server.crea-ticket.com";
const prefix = process.env.REACT_APP_PREFIX;
;
// Request Base
export const baseRequest = async<T>(route: string, config?: RequestInit): Promise<T> => {
    config = { credentials: 'include', ...(config || {}) };
    const requestResult = await fetch(`${baseURL}${prefix}/${route}`, config);
    return requestResult.json();
};

export const getDataLogin = async(token:string)=>{
    const requestResult = await fetch(`${baseURL}${prefix}/accesoCliente/getDataLogin`,{
        method: 'GET', // or 'PUT'
        credentials: 'include',
        headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token
        }
      });
        return requestResult.json();  
}
export const deleteClient = async(id: number) => {
    return await baseRequest<number>(`panelControl/deleteClient?id=${id}`);
}

export const getLoginPC = async (user: string, password: string) => {
    const request = await fetch(`${baseURL}${prefix}/panelControl/login`, {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',    
        body: JSON.stringify({user, password}),  
        method: 'POST'
    });   
    return request.json();
};

export const getClients = async (token: string) =>{
    const request = await fetch(`${baseURL}${prefix}/panelControl/getClients`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
        credentials: 'include', 
        method: 'GET'
    });
    return request.json();
}

export const getClientByID = async(id: number, token: string) => {
    const request = await fetch(`${baseURL}${prefix}/panelControl/getClientByID?id=${id}`, {
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token},
        credentials: 'include',
        method: 'GET'
    });
    return request.json();
}

export const createClient = async(token:string,idTicando: number, nombre: string, PUBLICAPIKEY: string, PRIVATEAPIKEY: string,
     email: string,  Dominio: string, pasarela_options:any) => {
        
        const request = await fetch(`${baseURL}${prefix}/panelControl/createClient`, {
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
        credentials: 'include',
        body: JSON.stringify({
            idTicando,
            nombre,
            PUBLICAPIKEY,
            PRIVATEAPIKEY,
            email,
            Dominio,
            pasarela_options
        }),
        method: 'POST'
    });
    return request.json();
}


export const updateClient = async(token:string, id: number, idTicando: number, nombre: string, PUBLICAPIKEY: string, 
    PRIVATEAPIKEY: string, email: String, Dominio: string, pasarela_options: any) => {           
                
        const request = await fetch(`${baseURL}${prefix}/panelControl/updateClient`, {
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token},
        credentials: 'include',
        body: JSON.stringify({
            id,
            idTicando,
            nombre,
            PUBLICAPIKEY, 
            PRIVATEAPIKEY,         
            Dominio,
            email,
            pasarela_options
        }),
        method: 'POST'
    });
    return request.json();
}

export const cambActivacion = async(token:string, id: number) => {
    const request = await fetch(`${baseURL}${prefix}/panelControl/deleteClient`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
        credentials: 'include',
        body:JSON.stringify({id:id}),
        method: 'POST'
    });
    return request.json();
}

export const getReservas = async (token: string) =>{
    const request = await fetch(`${baseURL}${prefix}/panelControl/getReservas`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
        credentials: 'include',
        method: 'GET'
    });
    return request.json();
}

export const getUsers = async(token:string, client_id)=>{
    const requestResult = await fetch(`${baseURL}${prefix}/panelControl/getUsers?client_id=${client_id}`,{
        method: 'GET',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        }
    });
    return requestResult.json();
}

export const getPasarelaOptions = async(name:string, client_id, token)=>{
    const requestResult = await fetch(`${baseURL}${prefix}/getPasarelaOptions?pasarela_name=${name}&client_id=${client_id}`,{
        method: 'GET',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        }
    });
    return requestResult.json();
}

export const getPasarelas= async () => {
    const requestResult = await fetch(`${baseURL}${prefix}/getPasarelas`,{
        method: 'GET',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
        }
    });
    return requestResult.json();
}

export const emitir = async (token, mes, año) => {
    const requestResult = await fetch(`${baseURL}${prefix}/emitir`,{
        method: 'POST',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        },
        body: JSON.stringify({mes, año})
    });
    return requestResult.text();
}

export const getCobros = async (token) => {
    const requestResult = await fetch(`${baseURL}${prefix}/getCobros`,{
        method: 'GET',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        },
    });
    return requestResult.json();
}

export const setPagadoBBDD = async (data, token) => {
    const requestResult = await fetch(`${baseURL}${prefix}/updateCobro`,{
        method: 'POST',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        },
        body: JSON.stringify({data})
    });
    return requestResult.json();
}