import React from "react";

interface ItemProps {
    name: string
    value: string
    placeholder: string
    required: boolean
    onChange(e:any)
    type: string
    readOnly: boolean
}

 // ({name, value, placeholder, required, onChange, type, readOnly}: ItemProps)
export const FormItem: React.FC<ItemProps> = (props: ItemProps)  => {
let req = props.required ? 'required' : ''

    return (
        <>
            <div className='row mb-6'>
              <label className={`col-lg-4 col-form-label ${req} fw-bold fs-6`}>{props.placeholder}</label>

              <div className='col-lg-8 fv-row'>
                  { 
                      props.readOnly &&
                      <input
                      type={props.type}
                      className='form-control form-control-lg form-control-solid' 
                      placeholder={props.placeholder} readOnly name={props.name} value={props.value} onChange={props.onChange} style ={{backgroundColor: '#fffff',padding:"20px 20px 25px" }}
                    />
    }
                  {
                      !props.readOnly && 
                      <>

                      
                      <input
                      type={props.type}
                      className='form-control form-control-lg form-control-solid' style={{backgroundColor: '#f5f5f5', padding: '20px 20px 25px;'}}
                      placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange}//onChange={(e)=>changeHandler(e.target.value)}
                    />
                     {
                        props.name === 'TERMINAL_GREENPAY' &&
                      <div className='col-lg-8 fv-row'>
                        <span style={{paddingLeft: '15px', color: 'mediumblue'}}>Escribir todos los Terminals ID de la cuenta de Greenpay separados por punto y coma.</span>
                      </div>
                      }
                      {
                        props.name === 'UUID_SERVICE_PAYNOPAIN' &&
                      <div className='col-lg-12 fv-row'>
                        <span style={{paddingLeft: '15px', color: 'mediumblue'}}>Debe asegurarse que el servicio de paynopain tiene la misma moneda que las experiencias disponibles, en caso contrario no se podrá reservar.</span>
                      </div>
                      }
                    </>
  }

              </div>
            </div>
        </>
    )
}
